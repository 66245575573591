@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.courses-outer {
    height: auto;
    width: 100%;
    margin-top: 80px;
}
.courses-dispatch-wrapper{
    width: 100%;
    height: auto;
    margin-bottom: 150px;
}

.courses-inner-wrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 150px;
}

.trainig-title-section {
    width: 20%;
    background-color: red;
    writing-mode: vertical-lr;
    text-orientation: upright;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.trainig-title-section p {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
}

.trainig-desc-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
}

.course-explain {
    width: 70%;
    height: auto;
}

.trainig-desc-section .wanna-make-dollars {
    font-size: 80px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
}

.trainig-desc-section .our-course {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 300;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    text-align: center;
}

.training-btn {
    /* position: absolute; */
    background-color: #ffeb3b;
    z-index: 1;
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 50px;
    font-weight: 600;
}

.training-btn p {
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    color: black;
    font-size: 17px;
    margin: 0;
}

.course-detailed-section {
    width: 100%;
    height: auto;

}

.course-detailed-section-title {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.course-detailed-section-title p {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    transition: 0.2s;
    margin: 0;
}

.course-detailed-section-title span {
    font-size: 50px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    transition: 0.2s;
}

.course-wrapper {
    display: flex;
    align-items: center;

}

.course-outer {
    width: 100%;
    height: 500px;
    border: 1px solid #ffeb3b;
}

.check-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.check-section-detail {
    width: 80%;
    background-color: blanchedalmond;
}

.check-wrapper {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid white;
}

.course-detail-outer {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.course-headlines-outer {
    height: auto;
    width: 100%;

}

.course-headlines-outer p {
    font-size: 30px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    transition: 0.2s;
}

.course-description-outer {
    height: auto;
    width: 80%;
}

.course-description-outer p {
    font-size: 15px;
    font-weight: 200;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    transition: 0.2s;
}
@media screen and (max-width: 500px) {
    .trainig-desc-section .wanna-make-dollars {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 800;
        font-family: "Poppins", system-ui;
        color: #ffeb3b;
    }
    .trainig-desc-section .our-course {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 300;
        font-family: "Poppins", system-ui;
        color: #ffeb3b;
        text-align: center;
    }
    .training-btn {
        /* position: absolute; */
        background-color: #ffeb3b;
        z-index: 1;
        width: 229px;
        height: 50px;
        border: none;
        border-radius: 50px;
        font-weight: 600;
    }
    .course-headlines-outer {
        height: auto;
        width: 100%;
        text-align: center;
    }
    .course-description-outer {
        height: auto;
        width: 100%;
        text-align: center;
    }
    .course-explain {
        width: 90%;
        height: auto;
    }
}