@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.jobs-us-wrapper {
    height: auto;
    width: 100%;
    margin-top: 80px;
}

.jobs-parallax {
    background-image: url("../../media/operator.jpeg");
    height: 1000px;
    width: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    margin-bottom: 150px;
}

.jobs-parallax-inner {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: rgba(76, 76, 76, 0.479);
    padding-top: 5%;
}

.jobs-parallax-text {
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
}

.jobs-parallax-text p {
    font-size: 100px;
    color: #ffeb3b;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    margin: 0;
    text-shadow: 8px 4px 26px rgba(0, 0, 0, 1);
}

.jobs {
    height: auto;
    width: 100%;

    margin-bottom: 150px;
}

.point-section {
    height: auto;
    width: 100%;
    margin-bottom: 100px;
}

.point-heading {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #ffeb3b;
    margin-bottom: 15px;
}

.point-heading p {
    font-size: 25px;
    color: black;
    font-weight: 700;
    text-transform: capitalize;
    font-style: oblique;
    font-family: "Poppins", system-ui;
    margin: 0;
    padding: 5px;
}

.point-description {
    height: auto;
    width: 100%;
}

.point-description img {
    object-fit: cover;
}

.choice-outer {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.choice-icon-sec {
    height: 100px;
    width: 100px;
    background-color: #ffeb3b;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choice-icon-sec span {
    font-size: 50px;
}

.choice-text-sec {
    height: 80px;
    width: 100%;
    background-color: #ffeb3b;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
}

.choice-text-sec .choice-text {
    font-size: 20px;
    color: black;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    margin: 0;
    text-align: center;
}

.communication-text {
    font-size: 20px;
    color: black;
    font-weight: 300;
    text-transform: lowercase;
    font-family: "Poppins", system-ui;
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .jobs-parallax-inner {
        height: 100%;
        width: 100%;
        position: absolute;
        overflow: hidden;
        background-color: rgba(76, 76, 76, 0.479);
        padding-top: 50%;
    }
    .jobs-parallax-text p {
        font-size: 50px;
        color: #ffeb3b;
        font-weight: bolder;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        margin: 0;
        text-shadow: 8px 4px 26px rgba(0, 0, 0, 1);
    }
    .point-heading p {
        font-size: 23px;
        color: black;
        font-weight: 700;
        text-transform: capitalize;
        font-style: oblique;
        font-family: "Poppins", system-ui;
        margin: 0;
        padding: 5px;
    }

}