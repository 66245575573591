@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.about-us-wrapper {
    height: auto;
    width: 100%;
    margin-top: 80px;
}

.we-are-rocking {
    height: auto;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background-image: url("../../media/vehicle_move.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.we-are-rocking p {
    font-size: 150px;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-weight: 600;
    color: #ffeb3b;
    text-shadow: white 9px 0px;
}

.movable-text {
    height: auto;
    width: 100%;
    background-color: rgb(81, 81, 81);
    overflow: hidden;
    display: flex;
    gap: 100px;
    margin-bottom: 80px;
}

.movable-sec p {
    color: white;
    font-size: 40px;
    font-style: italic;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-weight: 500;
    margin: 0;
    margin-left: 10px;
}

.movable-sec span {
    color: #ffeb3b;
    font-size: 40px;
    font-family: "Poppins", system-ui;
    font-weight: 500;
    margin: 0;
}

.movable-sec {
    display: flex;
    align-items: center;
    min-width: 600px;
    animation-name: moveText;
    animation-duration: 100s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes moveText {
    0% {
        transform: translateX(-2400px);
    }

    50% {
        transform: translateX(1600px);
    }

    100% {
        transform: translateX(-2400px);
    }
}
.about-market-stallion{
    width: 100%;
    height: auto;
    margin-bottom: 150px;

}
.about-heading{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-heading span{
    color: #ffeb3b;
    font-size: 50px;
    font-style: normal;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-weight: 600;
    margin: 0;
}
.about-heading p{
    color: #ffeb3b;
    font-size: 40px;
    font-style: normal;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-weight: 500;
    margin: 0;
}
.about-description{
    width: 100%;
    height: auto;
    text-align: center;
}
.about-description p{
    color: #ffeb3b;
    font-size: 20px;
    font-style: normal;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-weight: 300;
    margin: 0;
}
@media screen and (max-width: 500px) {
    .we-are-rocking p {
        font-size: 75px;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        font-weight: 600;
        color: #ffeb3b;
        text-shadow: white 9px 0px;
    }
    .about-heading p{
        color: #ffeb3b;
        font-size: 25px;
        font-style: normal;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        font-weight: 500;
        margin: 0;
    }
    .about-description p{
        color: #ffeb3b;
        font-size: 15px;
        font-style: normal;
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        font-weight: 300;
        margin: 0;
    }
    .movable-sec {
        display: flex;
        align-items: center;
        min-width: 500px;
        animation-name: moveTextMob;
        animation-duration: 50s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    
    @keyframes moveTextMob {
        0% {
            transform: translateX(400px);
        }
        100% {
            transform: translateX(-2200px);
        }
    }

}

