@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.hero-section {
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow: hidden;
    position: relative;
    margin-bottom: 150px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 77%;
    top: 80px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1;
}



.hero-middle {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    overflow: hidden;
}

.hero-middle-content {
    width: auto;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.hero-heading {
    font-size: 48px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    color: #fff;
    margin: 0;
}

.hero-footer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    position: relative;
}

.social-media-area {
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.menu-items-list {
    list-style: none;
    margin: 0;

}

.menu-items-list li {
    display: inline-block;
    padding-right: 20px;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    text-decoration: underline;
    cursor: pointer;
}

.phone p {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
}

.bussiness-slogan {
    width: 700px;
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}

.bussiness-slogan-text {
    text-align: center;
    margin: 0;
    font-size: 18px;
    font-family: "Poppins", system-ui;
    font-style: normal;
    text-transform: capitalize;
    color: #fff;
}

.dispatch-button {
    width: 300px;
    height: 50px;
    background-color: #ffeb3b;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

}

.dispatch-section p {
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
}

.dispatch-section {
    height: 100%;
    width: 100%;
    background-color: inherit;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dispatch-section-button-overlay {
    position: absolute;
    display: flex;
    transform: translateX(-300px);
    width: 270px;
    height: 50px;
    background-color: #ffeb3b;
    top: 0;
    transition: 2s;
    border-radius: 50px;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
}

.dispatch-button:hover .dispatch-section-button-overlay {
    width: 300px;
    height: 50px;
    background-color: #ffeb3b;
    /* background-color: #fff; */
    top: 0;
    position: absolute;
    display: flex;
    transform: translateX(-10px);
    transition: 2s;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.dispatch-section-button-overlay p {
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    color: black;
}

.dispatch-section-button-overlay img {
    animation-name: truckMov;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes truckMov {



    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(-40deg);
        ;
    }

    100% {
        transform: rotate(0deg);
    }
}

.social-icon span {
    font-size: 30px;
    color: #ffeb3b;
    cursor: pointer;
}

.line {
    height: 2.5px;
    width: 200px;
    background-color: #ffeb3b;
}

.social-and-line {
    display: flex;
    align-items: center;
    padding-right: 45px;
    padding-bottom: 20px;
}

.burger-btn {
    display: none;
}

@media screen and (max-width: 500px) {
    .menu-items-list {
        display: none;
    }

    .hero-section {
        width: 100%;
        height: 100%;
        background-color: #000;
        overflow: hidden;
        position: relative;
        margin-bottom: 100px;
        margin-top: 87px;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: -webkit-fill-available;
        top: 0px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        z-index: 1;
    }

    .hero-middle {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        overflow: hidden;
    }

    .line {
        height: 1.5px;
        width: 200px;
        background-color: #ffeb3b;
        display: none;
    }

    .hero-heading {
        font-size: 23px;
        font-weight: bold;
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        font-style: normal;
        color: #fff;
        margin: 0;
        margin-bottom: 5px;
    }

    .bussiness-slogan {
        /* width: 300px;
        height: auto;
        overflow: hidden;
        margin-bottom: 10px; */
        display: none;
    }

    .dispatch-button {
        width: 188px;
        height: 33px;
        background-color: #ffeb3b;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .bussiness-slogan-text {
        text-align: center;
        margin: 0;
        font-size: 13px;
        font-family: "Poppins", system-ui;
        font-style: normal;
        text-transform: capitalize;
        color: #fff;
    }

    .dispatch-section p {
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        font-style: normal;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
    }

    .social-icon span {
        font-size: 18px;
        color: #ffeb3b;
        cursor: pointer;
    }

    .phone {
        display: none;
    }

    .burger-btn {
        display: block;
    }

    .burger-btn span {
        font-size: 30px;
    }

   
    .hero-footer {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
        position: relative;
    }
    .social-and-line {
        display: flex;
        align-items: center;
        padding-right: 0px;
        padding-bottom: 20px;
    }
}