html,body{
    width: 100%;
    height: auto;
    background-color: #000;
    margin: 0;
  }

#root{
    width: 100%;
    height: 100%;
    background-color: #000;
}

#root .row {
  margin: 0;
  padding: 0px;

}
#root .col-style {
  margin: 0;
  margin-bottom: 20px;
}