@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.experience-section {
    width: 100%;
    height: auto;
    background-color: #ffeb3b;
    margin-bottom: 150px;
    overflow: hidden;
    min-height: 500px;
}

.experience-inner {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    min-height: 500px;
}

.left-arrow {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
}

.content {
    height: auto;
    width: 80%;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    padding: 10px;
}

.right-arrow {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
}

.hide-arrow {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.right-arrow span {
    font-size: 40px;
    cursor: pointer;
}

.hide-arrow span {
    font-size: 40px;
    cursor: pointer;
    color: #ffeb3b;
}

.left-arrow span {
    font-size: 40px;
    cursor: pointer;
}

.exp-heading-section {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.exp-heading {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}

.exp-heading p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-size: 40px;
    font-weight: 500;
    color: black;
}

.exp-heading span {
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    animation-name: arrowAnimation;
    animation-iteration-count: 1;
    animation-duration: 2s;
    color: black;
    transition: 0.2s;
}

.exp-slogan p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 20px;
    font-weight: 500;
    color: black;
    text-align: center;
}

.content-left {
    width: 30%;
    border-radius: 10px;
    overflow: hidden;
}

.content-left img {
    object-fit: cover;
    object-position: center;
}

.content-right {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.quote {
    width: 100%;
    height: auto;
    text-align: right;
}

.quote span {
    font-size: 50px;
    margin: 0;
}

.text {
    width: 100%;
    height: auto;
    padding-left: 10px;
}

.text p {
    margin: 0;
    word-break: normal;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: italic;
}

.who {
    width: 100%;
    height: auto;
    padding-left: 10px;
}

.who p {
    margin: 0;
    word-break: break-all;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
}

.who span {
    margin: 0;
    word-break: break-all;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: italic;

}
.exp-heading-section-mob {
  
    display: none;
}

@media screen and (max-width: 500px) {
    .experience-section {
        width: 100%;
        height: auto;
        background-color: #ffeb3b;
        margin-bottom: 150px;
        overflow: hidden;
    }
    .experience-inner {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
    }

    .exp-heading-section {
        display: none;
    }

    .exp-heading-section-mob {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .content-left {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
    }

    .content {
        height: auto;
        width: 80%;
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        padding: 10px;
        flex-direction: column;
    }

    .content-right {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        overflow: auto;
    }
    .icon-right-down{
        display: none;
    }
    .exp-heading p {
        margin: 0;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        font-size: 30px;
        font-weight: 500;
        color: black;
    }

}