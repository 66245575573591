@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.services-outer {
    height: auto;
    width: 100%;
    /* background-color: aliceblue; */
    
}

.service-outer {
    height: 250px; 
    width: 100%;
    border: 1px solid #ffeb3b;
    border-radius: 10px;
    overflow: hidden;
}

.service-heading-section {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.service-heading {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}

.service-heading p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-size: 40px;
    font-weight: 500;
    color: #ffeb3b;
}

.service-heading span {
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    animation-name: arrowAnimation;
    animation-iteration-count: 1;
    animation-duration: 2s;
    color: #ffeb3b;
    transition: 0.2s;
}

@keyframes arrowAnimation {
    from {
        transform: translateY(-100px);
    }

    to {
        transform: translateY(0);
    }
}

.service-slogan p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 20px;
    font-weight: 500;
    color: #ffeb3b;
    text-align: center;
}

.service-inner-heading-text {
    width: 100%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.service-inner-heading-text p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 20px;
    font-weight: 500;
    color: #ffeb3b;
    text-align: center;
}

.service-inner-description-text {
    width: 100%;
    height: 50%;

    display: flex;
    justify-content: center;
}

.service-inner-description-text p {
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 15px;
    font-weight: 300;
    color: #ffeb3b;
    text-align: center;
}