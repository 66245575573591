@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lnihyu');
  src:  url('fonts/icomoon.eot?lnihyu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lnihyu') format('truetype'),
    url('fonts/icomoon.woff?lnihyu') format('woff'),
    url('fonts/icomoon.svg?lnihyu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-recipient:before {
  content: "\e916";
}
.icon-7:before {
  content: "\e917";
}
.icon-delivery:before {
  content: "\e915";
}
.icon-setup:before {
  content: "\e914";
}
.icon-hand:before {
  content: "\e913";
}
.icon-high-quality:before {
  content: "\e90f";
}
.icon-location:before {
  content: "\e910";
}
.icon-excellence:before {
  content: "\e911";
}
.icon-leader:before {
  content: "\e912";
}
.icon-quotation:before {
  content: "\e90e";
}
.icon-left-arrow:before {
  content: "\e90c";
}
.icon-right-arrow1:before {
  content: "\e90d";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-top-right:before {
  content: "\e90a";
}
.icon-right-down:before {
  content: "\e909";
}
.icon-right:before {
  content: "\e908";
}
.icon-whatsapp:before {
  content: "\e900";
}
.icon-live-chat:before {
  content: "\e906";
}
.icon-world:before {
  content: "\e905";
}
.icon-right-arrow:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e903";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-facebook-logo-in-circular-shape:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e907";
}
