@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.join-our-team-wrapper {
    width: 100%;
    height: auto;
    padding: 20px;
    margin-bottom: 150px;
}

.join-team-text {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.join-team-pic {
    width: 100%;
    height: auto;
    position: relative;
}

.yellow-circle {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: #ffeb3b;
    top: 25%;
    left: 25%;
    border-radius: 100px;
}

.operator-img {
    height: auto;
    width: auto;
    position: absolute;
    top: 20px;
    left: 0px;
    box-shadow: 17px 15px 0px #ffeb3b;
    border-radius: 5px;
    overflow: hidden;
}

.operator-img img {
    object-fit: cover;
    object-position: center;
}

.join-team-heading {
    width: 100%;
    height: auto;
}

.join-team-heading p {
    text-align: left;
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    word-break: keep-all;
    margin: 0;
}

.join-team-description p {
    text-align: left;
    font-size: 15px;
    font-weight: 300;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    color: #ffeb3b;
    word-break: keep-all;
    margin: 0;
}

.join-team-description {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.join-team-btn {
    width: 100%;
    height: auto;

}

.team-join-btn {
    width: 300px;
    height: 50px;
    background-color: #ffeb3b;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.team-join-btn p {
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    color: black;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
}

@media screen and (max-width: 500px) {
    .join-team-heading p {
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        color: #ffeb3b;
        word-break: keep-all;
        margin: 0;
    }

    .join-team-description p {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        color: #ffeb3b;
        word-break: keep-all;
        margin: 0;
    }

    .join-our-team-wrapper {
        width: 100%;
        height: auto;
        padding: 20px;
        margin-bottom: 0px;
        margin-bottom: 150px;
    }

    .operator-img {
        height: auto;
        width: auto;
        position: relative;
        top: 20px;
        left: 0px;
        box-shadow: 17px 15px 0px #ffeb3b;
        border-radius: 5px;
        overflow: hidden;
    }

}