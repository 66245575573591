@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  width: 100%;
  height: 100%;
  position: relative;
}

.text-color-white {
  font-size: 15px;
  text-align: center;
  margin: 0;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.live-chat {
  height: auto;
  width: auto;
  padding: 10px;
  border-radius: 10px;
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 3;
  background-color: rgb(46, 46, 46);
  cursor: pointer;
}

.live-chat span {
  font-size: 30px;
  color: #ffeb3b;
  font-weight: 500;
}

.live-chat-section {
  height: 250px;
  width: 250px;
  position: fixed;
  bottom: 30px;
  border-radius: 10px;
  outline: none;
  left: 30px;
  z-index: 4;
  background-color: white;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.header p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.close-icon p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.chat-area {
  height: 200px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: flex-end;
}

.chat-action-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.chat-msg {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chater-name-admin {
  height: auto;
  width: auto;
  background-color: #ffeb3b;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
}

.chater-name-admin p {
  margin: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
}

.chater-name-user {
  height: auto;
  width: auto;
  background-color: black;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
}

.chater-name-user p {
  margin: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
  color: white;
}

.chater-name p {
  margin: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
}

.chater-msg {

  width: auto;
  max-width: 70%;
  height: auto;
  word-wrap: break-word;
  padding: 5px;
}

.chater-msg p {
  word-wrap: break-word;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-size: 12px;
}

.chat-area::-webkit-scrollbar {
  width: 10px;
}


.chat-area::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.chat-area::-webkit-scrollbar-thumb {
  background: #888;
}


.chat-area::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.input-area {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.input-area input {
  height: 91%;
  width: 95%;
  border: 1px solid black;
  border-radius: 5px;
}

.send-btn {
  border: 1px solid #ffeb3b;
  background-color: #ffeb3b;
  border-radius: 5px;
}
.send-btn p{
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.whatsapp-outer{
  height: auto;
  width: auto;
  background-color: white;
  position: fixed;
  bottom: 90px;
  border-radius: 10px;
  outline: none;
  left: 30px;
  z-index: 4;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.whatsapp-outer span{
  color: green;
  font-size: 30px;
}
.start-chat{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.start-chat p{
  margin: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}
.start-chat-icon span{
  font-size: 40px;
  font-weight: 500;
  color: #ffeb3b;
}