@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer-wrapper {
    width: 100%;
    height: auto;
    background-color: #ffeb3b;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.scroll-top-btn {
    height: 120px;
    width: 80px;
    border-radius: 50px;
    border: 10px solid black;
    background-color: #ffeb3b;
    position: absolute;
    top: -50px;
    right: 50px;
}

.scroll-top-btn span {
    color: black;
    font-size: 35px;
}

.top-face-arrow {

    animation-name: topArrowAnim;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes topArrowAnim {

    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.footer-content {
    width: 100%;
    height: 100%;
    padding-top: 50px;
}

.footer-intro {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer-middle {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer-future {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer-intro-heading {
    height: auto;
    width: auto;
}

.footer-intro-heading p {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: normal;

}

.social-media-area-in-footer {
    width: 250px;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.social-icon-footer span {
    font-size: 30px;
    color: black;
    cursor: pointer;
}

.FAQs {
    width: 100%;
    height: auto;
}

.FAQs p {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.luanching {
    width: 100%;
    height: auto;
}

.luanching p {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    margin: 0;
    text-align: center;
}

.google-play {
    width: 200px;
    height: 100px;
    
}

.google-play img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.copy-rights {
    width: 100%;
    height: auto;
    background-color: black;
}

.copy-rights p {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    margin: 0;
    text-align: center;
    color: white;
}

.subscribe-news{
    width: 100%;
    height: auto;
}

.subscribe-news p{
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    text-align: center;
    color: black;
}

@media screen and (max-width: 500px) {
    .footer-intro {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
    }
    .footer-middle {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
    }
    .scroll-top-btn{
        display: none;
    }
}