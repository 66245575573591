@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.truck-types-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: 150px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.truck-heading-section {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
}

.truck-header {
    display: flex;
    align-items: center;
}

.truck-header p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-size: 40px;
    font-weight: 500;
    color: #ffeb3b;
}

.truck-header span {
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    animation-name: arrowAnimation;
    animation-iteration-count: 1;
    animation-duration: 2s;
    color: #ffeb3b;
    transition: 0.2s;
}

.truck-slogan p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 20px;
    font-weight: 500;
    color: #ffeb3b;
    text-align: center;
}

.truck-types-section {
    width: 100%;
    height: 100%;
    position: relative;
}

.truck-type-border {
    height: 400px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-outer img {
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.img-outer {
    height: 350px;
    width: 80%;
    border-radius: 10px;
    box-shadow: 17px 15px 0px #ffeb3b;
    overflow: hidden;

}

.truck-name {
    width: 100%;
    height: auto;
}

.truck-description {
    width: 100%;
    height: auto;
}

.truck-name p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 30px;
    font-weight: 600;
    color: #ffeb3b;
    text-align: left;
}

.truck-description p {
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: oblique;
    font-size: 17px;
    font-weight: 300;
    color: #ffeb3b;
    text-align: left;
}

@media screen and (max-width: 500px) {
    .truck-name p {
        margin: 0;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        font-style: oblique;
        font-size: 30px;
        font-weight: 600;
        color: #ffeb3b;
        text-align: center;
    }
    .truck-description p {
        margin: 0;
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        font-style: oblique;
        font-size: 17px;
        font-weight: 300;
        color: #ffeb3b;
        text-align: center;
    }
}