@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.priceplans-wrapper {
    width: 100%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 150px;
}
.price-plan-header-wrapper{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-bottom: 10px; */
}

.header-priceplans {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-priceplans p {
    margin: 0;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    font-size: 40px;
    font-weight: 500;
    color: #ffeb3b;
}

.header-priceplans span {
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    /* animation-name: arrowAnimation; */
    animation-iteration-count: 1;
    animation-duration: 2s;
    color: #ffeb3b;
    transition: 0.2s;
}
.slogan-priceplans{
    width: 100%;
    height: auto;
    text-align: center;
}

.slogan-priceplans p {
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-size: 15px;
    font-weight: 300;
    color: #ffeb3b;
}
.pricing-table{
    width: 100%;
    height: auto;

}
.pricing-table-text{
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
}
.training-btn {
    /* position: absolute; */
    background-color: #ffeb3b;
    z-index: 1;
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 50px;
    font-weight: 600;
}

.training-btn p {
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    color: black;
    font-size: 17px;
    margin: 0;
}

@media screen and (max-width: 500px) {
    .pricing-table-text {
        text-transform: capitalize;
        font-family: "Poppins", system-ui;
        font-size: 13px;
    }
}

