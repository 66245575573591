@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.make-life-easy {
    width: 100%;
    height: auto;
    background-color: #ffeb3b;
    padding: 20px;
    margin-bottom: 150px;
}

.make-life-easy-text p {
    text-align: center;
    font-size: 80px;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Poppins", system-ui;
    color: black;
    word-break: keep-all;
    margin: 0;
}

.btn-area {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prices-btn {
    width: 300px;
    height: 60px;
    background-color: black;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.prices-btn p {
    color: white;
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    font-size: 23px;
    font-weight: 800;
}

.prices-btn:hover p {
    color: #ffeb3b;
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", system-ui;
    font-style: normal;
    font-size: 23px;
    font-weight: 800;
}

@media screen and (max-width: 500px) {
    .make-life-easy-text p {
        text-align: center;
        font-size: 40px;
        font-weight: 800;
        text-transform: uppercase;
        font-family: "Poppins", system-ui;
        color: black;
        word-break: keep-all;
        margin: 0;
    }
}